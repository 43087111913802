import { decorationBackgroundSVG, toRem } from '../../../../../helpers/theme'

const Style = ({ theme, variant }) => {
    const flexBase = {
        display: 'flex',
        alignItems: 'center'
    }

    const config = {
        '1x2': {
            desktop: {
                order: 1,
                marginBottom: toRem(18),
                direction: 'column',
                align: 'center',
                width: toRem(60),
                height: toRem(60)
            },
            mobile: {
                order: 1,
                marginBottom: toRem(18),
                direction: 'column',
                align: 'center',
                width: toRem(50),
                height: toRem(50)
            }
        },
        '2x1': {
            desktop: {
                order: 0,
                marginRight: toRem(10),
                marginBottom: 0,
                direction: 'row',
                align: 'flex-end',
                width: toRem(46),
                height: toRem(46)
            },
            mobile: {
                order: 1,
                marginBottom: toRem(18),
                marginRight: 0,
                direction: 'column',
                align: 'center',
                width: toRem(50),
                height: toRem(50)
            }
        }
    }

    return {
        ...flexBase,
        flexDirection: config[variant]?.mobile.direction,
        marginBottom: toRem(18),
        [theme.breakpoints.up('md')]: {
            flexDirection: config[variant]?.desktop.direction
        },
        '& .header_title': {
            order: config[variant]?.mobile?.order,
            marginRight: config[variant]?.mobile?.marginRight,
            [theme.breakpoints.up('md')]: {
                order: config[variant]?.desktop?.order,
                marginRight: config[variant]?.desktop?.marginRight
            }
        },
        '& .header_separator': {
            ...flexBase,
            flex: 1,
            justifyContent: config[variant]?.mobile.align,
            width: '100%',
            marginBottom: config[variant]?.mobile.marginBottom,
            [theme.breakpoints.up('md')]: {
                justifyContent: config[variant]?.desktop.align,
                marginBottom: config[variant]?.desktop.marginBottom
            },
            backgroundImage: decorationBackgroundSVG(theme.colors.gray300),
            backgroundRepeat: 'repeat-x',
            backgroundPosition: 'center',
            '&Circle': {
                ...flexBase,
                justifyContent: 'center',
                width: config[variant]?.mobile.width,
                height: config[variant]?.mobile.height,
                [theme.breakpoints.up('md')]: {
                    width: config[variant]?.desktop.width,
                    height: config[variant]?.desktop.height
                },
                borderRadius: theme.global.borderRadius.rounded,
                backgroundColor: theme.colors.accentYellow,
                outline: `${toRem(10)} solid ${theme.colors.white}`
            }
        }
    }
}

export default Style
