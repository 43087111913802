/* eslint-disable quote-props, no-dupe-keys, max-len */

// @ui imports
import { responsive, toRem, toRems } from '../../../../helpers/theme'

const style = ({ theme, variant }) => {
    const config = {
        '1x2': {
            desktop: {
                minHeight: '520px',
                header: {
                    textAlign: 'center',
                    marginBottom: toRem(57),
                    padding: toRems([25, 35])
                },
                avatar: {
                    marginTop: toRem(-67)
                },
                title: {
                    marginTop: toRem(15),
                    textAlign: 'center'
                },
                card: {
                    padding: toRems([0, 20, 30, 30])
                }
            },
            mobile: {
                header: {
                    textAlign: 'center',
                    marginBottom: toRem(41),
                    padding: toRems([25, 35])
                },
                avatar: {
                    marginTop: toRem(-61)
                },
                title: {
                    marginTop: toRem(10),
                    align: 'center'
                },
                card: {
                    padding: toRems([0, 20, 30, 30])
                }
            }
        },
        '2x1': {
            desktop: {
                minHeight: '250px',
                header: {
                    textAlign: 'center',
                    marginBottom: 0,
                    padding: toRems([25, 10])
                },
                avatarOuter: {
                    display: 'inline-flex',
                    alignItems: 'center',
                    marginRight: toRem(30)
                },
                avatar: {
                    marginTop: 0
                },
                title: {
                    paddingLeft: toRem(32),
                    align: 'flex-start'
                },
                card: {
                    padding: toRems([25, 30, 30])
                },
                userInner: {
                    display: 'flex',
                    flexDirection: 'row'
                }
            },
            mobile: {
                header: {
                    textAlign: 'center',
                    marginBottom: toRem(41),
                    padding: toRems([25, 35])
                },
                avatarOuter: {},
                avatar: {
                    marginTop: toRem(-51)
                },
                title: {
                    paddingLeft: 'unset',
                    align: 'center'
                },
                card: {
                    padding: toRems([25, 30, 30])
                },
                userInner: {
                    display: 'flex',
                    flexDirection: 'column'
                }
            }
        }
    }

    return {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: toRem(10),
        borderRadius: theme.global.borderRadius.medium,
        backgroundColor: theme.colors.background.card.base,
        boxShadow: theme.shadows.subtle,
        [theme.breakpoints.up('md')]: {
            minHeight: config[variant]?.desktop?.minHeight
        },
        '& .coolChefCurrent': {
            '&_header': {
                marginBottom: config[variant]?.mobile?.header?.marginBottom,
                padding: config[variant]?.mobile?.header?.padding,
                textAlign: config[variant]?.mobile?.header?.textAlign,
                [theme.breakpoints.up('md')]: {
                    marginBottom: config[variant]?.desktop?.header?.marginBottom,
                    padding: config[variant]?.desktop?.header?.padding,
                    textAlign: config[variant]?.desktop?.header?.textAlign
                }
            },
            '&_cardAvatar': {
                ...config[variant]?.mobile.avatarOuter,
                [theme.breakpoints.up('md')]: {
                    ...config[variant]?.desktop.avatarOuter
                },
                '.avatarInner': {
                    display: 'inline-flex',
                    alignItems: 'center',
                    marginTop: config[variant]?.mobile?.avatar?.marginTop,
                    [theme.breakpoints.up('md')]: {
                        marginTop: config[variant]?.desktop?.avatar?.marginTop
                    }
                }
            },
            '&_user': {
                width: '100%',
                height: '100%',
                '&Inner': {
                    height: '100%',
                    background: theme.colors.card.coolChef.user.backgroud,
                    padding: config[variant].mobile.card.padding,
                    [theme.breakpoints.up('md')]: {
                        ...config[variant]?.desktop?.userInner,
                        padding: config[variant].desktop.card.padding
                    }
                },
                '&Name': {
                    display: 'flex',
                    justifyContent: config[variant].mobile.title.align,
                    marginTop: config[variant].mobile.title.marginTop,
                    [theme.breakpoints.up('md')]: {
                        justifyContent: config[variant].desktop.title.align,
                        marginTop: config[variant].desktop.title.marginTop,
                        paddingLeft: config[variant].desktop.title.paddingLeft
                    },
                    // marginBottom: toRem(30),
                    ...responsive([
                        { fontSize: toRem(24) },
                        { fontSize: toRem(24) },
                        { fontSize: toRem(26) },
                        { fontSize: toRem(26) },
                        { fontSize: toRem(28) },
                        { fontSize: toRem(28) }
                    ]),
                    lineHeight: 1.1,
                    fontWeight: theme.typography.fontWeight.black
                },
                '&Quote': {
                    width: '100%',
                    marginTop: toRem(15),
                    marginBottom: toRem(16),
                    [theme.breakpoints.up('md')]: {
                        marginTop: toRem(25)
                    },
                    blockquote: {
                        position: 'relative',
                        margin: 0,
                        paddingLeft: toRem(30),
                        fontSize: toRem(16),
                        lineHeight: 1.6,
                        fontWeight: theme.typography.fontWeight.light,
                        fontStyle: theme.typography.font.style.italic,
                        [theme.breakpoints.up('md')]: {
                            paddingLeft: toRem(32),
                            fontSize: toRem(18)
                        },
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            display: 'block',
                            width: toRem(20),
                            height: toRem(16),
                            background: `url(/svg/icons/open-quote-red.svg) no-repeat center / contain`,
                            [theme.breakpoints.up('md')]: {
                                width: toRem(22),
                                height: toRem(18)
                            }
                        }
                    }
                },
                '&Link': {
                    paddingLeft: toRem(30),
                    [theme.breakpoints.up('md')]: {
                        paddingLeft: toRem(32)
                    }
                }
            }
        }
    }
}

export default style
