import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { HeadingSection, headingSectionSizes } from '../../../../Heading/components/Section'
import { HtmlContent } from '../../../../HtmlContent'
import styles from './Header.style'

const HeaderStyled = styled.div(props => ({ ...styles(props) }))

const headingSizeVariants = Object.freeze({
    SMALL: 'small',
    HEADING_SMALLER: 'heading_smaller'
})

const Header = ({ headingSize, iconComponent: Icon, title, className, ...props }) => (
    <HeaderStyled className={className} {...props}>
        {title && (
            <HeadingSection className="header_title" TagComponent="p" size={headingSectionSizes[headingSize]}>
                <HtmlContent wrapperComponent="span" content={title} />
            </HeadingSection>
        )}
        {Icon && (
            <div className="header_separator">
                <span className="header_separatorCircle">{Icon}</span>
            </div>
        )}
    </HeaderStyled>
)

Header.propTypes = {
    iconComponent: PropTypes.node,
    title: PropTypes.string,
    className: PropTypes.string,
    headingSize: PropTypes.oneOf([...Object.values(headingSizeVariants)])
}

Header.defaultProps = {
    iconComponent: undefined,
    title: undefined,
    className: undefined,
    headingSize: headingSizeVariants.SMALL
}
export { headingSizeVariants }

export default Header
