import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { useOneElastic } from '@hmn/coolinarika-web-core/hooks'

import { Button, buttonSizes, buttonVariants } from '../../../Button'
import { withErrorBoundary } from '../../../ErrorBoundary'
import { ArrowSmallIcon, ChefIcon, Icon } from '../../../Icon'
import { UserAvatar, UserAvatarVariants } from '../../../User/components/Avatar'
import styles from './CardCurrent.style'
import { CardCurrentHeader } from './Header'

const CoolChefCardCurrentStyled = styled.div(props => ({ ...styles(props) }))

const CoolChefCardCurrent = ({
    buttonLink,
    buttonTitle,
    children,
    userId,
    title,
    className,
    quote,
    variant,
    ...rest
}) => {
    const { data: user } = useOneElastic({ resource: 'ljudi', id: userId, enabled: !!userId })

    if (!userId) {
        return null
    }

    return (
        <CoolChefCardCurrentStyled className={className} {...rest} variant={variant}>
            <CardCurrentHeader
                className="coolChefCurrent_header"
                title={title}
                iconComponent={<Icon icon={ChefIcon} size={32} />}
                variant={variant}
            />

            <div className="coolChefCurrent_user">
                <div className="coolChefCurrent_userInner">
                    <UserAvatar
                        href={user?.public_url}
                        as={user?.public_url}
                        className="coolChefCurrent_cardAvatar"
                        userId={userId}
                        variant={UserAvatarVariants.COOLCHEF}
                    />
                    <div className="coolChefCurrent_body">
                        {(user?.display_name || user?.fname) && (
                            <div className="coolChefCurrent_userName">
                                <span>{user?.display_name || user?.fname}</span>
                            </div>
                        )}
                        {quote && (
                            <div className="coolChefCurrent_userQuote">
                                <blockquote>{quote}</blockquote>
                            </div>
                        )}
                        {buttonLink && (
                            <div className="coolChefCurrent_userLink">
                                <Button
                                    isActionLink
                                    title={buttonTitle}
                                    href={buttonLink}
                                    iconRightComponent={<Icon icon={ArrowSmallIcon} />}
                                    variant={buttonVariants.PRIMARY}
                                    size={buttonSizes.SMALL}>
                                    {buttonTitle}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </CoolChefCardCurrentStyled>
    )
}

const coolChefCardCurrentVariants = Object.freeze({
    '1x2': '1x2',
    '2x1': '2x1'
})

CoolChefCardCurrent.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    quote: PropTypes.string,
    variant: PropTypes.oneOf([...Object.values(coolChefCardCurrentVariants)]),
    buttonLink: PropTypes.string,
    buttonTitle: PropTypes.string
}

CoolChefCardCurrent.defaultProps = {
    title: undefined,
    className: undefined,
    userId: undefined,
    quote: undefined,
    variant: coolChefCardCurrentVariants['1x2'],
    buttonLink: undefined,
    buttonTitle: undefined
}

export { coolChefCardCurrentVariants }

export default withErrorBoundary(CoolChefCardCurrent)
