/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../utils'

const Chef = createSvgIcon(
    <>
        <path
            d="M26.684 19.31a5.12 5.12 0 01-4.557.653.453.453 0 00-.414.058l-2.04 1.446.526.739 1.85-1.314a6.057 6.057 0 005.14-.83c.267-.181.52-.381.757-.6l-.614-.666c-.203.188-.42.36-.648.515z"
            fill="#141313"
        />
        <path
            d="M22.12 12.548a4.12 4.12 0 00-1.876 3.93L14.5 20.16l-5.745-3.68a4.102 4.102 0 00-1.878-3.928 4.333 4.333 0 00-2.955-.737 3.353 3.353 0 00-2.89 4.284 4.329 4.329 0 001.79 2.46 4.1 4.1 0 004.35.27l4.46 3.166-4.244 2.72a1.775 1.775 0 00-.015 2.99 1.778 1.778 0 002.001-.04l5.126-3.636 5.126 3.638a1.78 1.78 0 002.506-2.464 1.78 1.78 0 00-.518-.488l-4.248-2.719 4.462-3.167a4.101 4.101 0 004.35-.268c1.862-1.257 2.47-3.628 1.354-5.287-1.116-1.658-3.545-1.983-5.411-.726zM8.85 26.927a.895.895 0 01-1.23-.224.87.87 0 01.255-1.224l4.553-2.92 1.287.913-4.865 3.455zm12.66-.89a.874.874 0 01-1.362.89L7.443 17.912a.454.454 0 00-.53.003 3.14 3.14 0 01-3.584-.105 3.427 3.427 0 01-1.42-1.944 2.447 2.447 0 012.129-3.154 3.427 3.427 0 012.334.59 3.14 3.14 0 011.439 3.283.453.453 0 00.194.493l13.12 8.402a.867.867 0 01.385.558zm4.16-8.23a3.141 3.141 0 01-3.585.105.453.453 0 00-.53-.003l-5.012 3.559-1.202-.77 5.652-3.626a.453.453 0 00.195-.491 3.156 3.156 0 011.44-3.284c1.45-.978 3.314-.762 4.152.48.838 1.24.34 3.053-1.11 4.03zM5.858 1.737L5.356.982a5.89 5.89 0 00-2.3 6.872l.854-.302a4.984 4.984 0 011.948-5.815zM4.871 9.184a5.042 5.042 0 01-.577-.801l-.785.453c.196.337.425.654.682.947l.68-.6z"
            fill="#141313"
        />
        <path
            d="M19.475 16.403l1.29-6.45a4.078 4.078 0 00-.374-8.14 4.028 4.028 0 00-2.523.876 4.071 4.071 0 00-6.736 0 4.078 4.078 0 10-2.898 7.262l1.29 6.452.889-.177-.254-1.273h8.682l-.254 1.269.888.181zm-2.187-2.356l.38-2.655-.898-.128-.397 2.783h-1.42V9.062h-.906v4.985h-1.42l-.397-2.783-.897.128.379 2.655H9.978l-.925-4.622a.453.453 0 00-.444-.363 3.172 3.172 0 111.256-6.085 3.14 3.14 0 011.048.739.453.453 0 00.736-.114 3.165 3.165 0 015.702 0 .454.454 0 00.736.114 3.14 3.14 0 012.304-.997 3.172 3.172 0 110 6.343.453.453 0 00-.444.363l-.925 4.622h-1.734z"
            fill="#141313"
        />
    </>,
    'Chef'
)

export default Chef
